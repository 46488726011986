body {
  margin: 0;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white !important;
  /* more styles */
  font-size: 20px;
}

.card-shadow {
  box-shadow: 0 4px 4px rgba(0,0,0,0.25) !important;
}

.faint {
  opacity: 0.25;
}

.center-div {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
